<template>
  <base-selector-autocomplete
    :items="items"
    :initial-value="initialValue"
    :is-loading="isLoading"
    item-text="full_name"
    item-value="_id"
    label="Select credentials"
    @lazy-load="load"
    @select="newSelect => $emit('select', newSelect, 'credentials')"
    @input.native="makeSearch"
  />
</template>

<script>
  import { getCredentials } from '@/api/credentialsMethods';
  import BaseSelectorAutocomplete from '@/components/base/selectors/BaseSelectorAutocomplete.vue';
  import { debounce } from 'lodash';
  export default {
    name: 'BaseCredentialsSelector',
    components: {
      BaseSelectorAutocomplete,
    },
    props: {
      initialValue: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        items: [],
        isLoading: false,
        loadMore: true,
        queue: false,
        offset: 0,
        q: this.initialValue?._id || '',
      };
    },
    created () {
      this._makeSearch = debounce(this._makeSearch.bind(this), 300);
    },
    async mounted () {
      const items = [{
        full_name: 'all users',
        _id: '',
      }];
      this.items = items;
      if (this.q) {
        await this._makeSearch();
      }
    },
    methods: {
      async load () {
        if (this.loadMore) {
          this.offset = this.items.length;
          await this._makeSearch(true);
        }
      },
      async _makeSearch (lazyLoad) {
        if (this.isLoading) {
          this.queue = true;
          return;
        }
        try {
          this.isLoading = true;
          const response = await getCredentials(this.offset, 10, false, '', '', this.q);
          this.loadMore = !!response.length;
          response.forEach(c => (c.full_name = `${c._id} ${c.username} ${c.first_name} ${c.last_name}`));
          if (lazyLoad) {
            this.items = [...this.items, ...response];
          } else {
            this.items = [
              {
                full_name: 'all users',
                _id: '',
              },
              ...response,
            ];
          }
          if (this.queue) {
            await this._makeSearch(lazyLoad);
          }
        } catch (e) {
          this.$noty.error(e.message);
          console.error(e);
        } finally {
          this.isLoading = false;
        }
      },
      async makeSearch (e) {
        this.loadMore = true;
        this.q = e.target.value;
        this.items = [];
        this.offset = 0;
        return this._makeSearch(false);
      },
    },
  };
</script>

<style scoped>

</style>
